import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const DefaultPicture = ({
  className = '',
  sources,
  fallbackSrc,
  alt = '',
  loading = null,
  preload = null,
  ...props
}) => {
  if (!sources || !sources.length) {
    return null;
  }

  if (preload) {
    return (
      <>
        <Head>
          {preload.map((data) => (
            <link
              rel="preload"
              href={data.href}
              imageSrcSet={data.imageSrcSet}
              as="image"
              type={data.type}
              media={data.media}
              key={data.imageSrcSet}
            />
          ))}
        </Head>
        <picture className={`default-picture ${className}`}>
          {sources.map((source) => (
            <source {...source} key={source.srcSet} />
          ))}
          <img src={fallbackSrc} alt={alt ?? ''} loading="eager" {...props} />
        </picture>
      </>
    );
  }

  return (
    <picture className={`default-picture ${className}`}>
      {sources.map((source, index) => (
        <source {...source} key={`${source.srcSet}-${index}`} />
      ))}
      <img src={fallbackSrc} alt={alt ?? ''} loading={loading} {...props} />
    </picture>
  );
};

const propTypes = {
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      srcSet: PropTypes.string,
      media: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  fallbackSrc: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.string,
  preload: PropTypes.arrayOf(
    PropTypes.shape({
      imageSrcSet: PropTypes.string,
      href: PropTypes.string,
      media: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};

DefaultPicture.propTypes = propTypes;

export { propTypes };
export default DefaultPicture;
